import { render, staticRenderFns } from "./TaxiTransactionsHistory.vue?vue&type=template&id=f9288878&scoped=true"
import script from "./TaxiTransactionsHistory.vue?vue&type=script&lang=js"
export * from "./TaxiTransactionsHistory.vue?vue&type=script&lang=js"
import style0 from "./TaxiTransactionsHistory.vue?vue&type=style&index=0&id=f9288878&prod&lang=scss&scoped=true"
import style1 from "./TaxiTransactionsHistory.vue?vue&type=style&index=1&id=f9288878&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9288878",
  null
  
)

export default component.exports